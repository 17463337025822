export const lineText = (text) => {
  const newText = text
    ?.split("\n")
    ?.map((str) => (str == "  " ? "" : <p style={{ margin: 0 }}>{str}</p>));
  // ?.map((str) => console.log("str", str));
  return newText;
};

export const listTolineText = (list) => {
  const newText = list?.map((text) =>
    text?.split("\n")?.map((str) => <p style={{ margin: 0 }}>{str}</p>)
  );
  return newText;
};

export const findKeyByValue = (obj, targetValue) => {
  for (let key in obj) {
    if (obj[key] === targetValue) {
      return key;
    }
  }
  return null;
};
