import { useEffect } from "react";
import Loading from "../components/Loading";
import { useParams } from "react-router-dom";
import { apiUrl } from "../config/settings";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SmartQr = () => {
  const { guid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (guid) {
      axios
        .get(`${apiUrl}/smartQr/${guid}`)
        .then((response) => {
          if (response.status === 200) {
            const data = response?.data?.data;

            if (data?.url) {
              closedTab(data?.url);
            } else if (data?.contact) {
              downloadCsv(data?.contact);
            } else if (data?.clientServiceCall) {
              navigate(
                `/clientServiceCall/${data?.clientServiceCall?.baseUrl}`
              );
            }
          }
        })
        .catch((error) => {});
    } else {
      closedTab();
    }
  }, [guid]);

  const downloadCsv = (data) => {
    var contact = {
      name: data.name,
      phone: data.tel,
      email: data.email,
    };

    // create a vcard file
    var vcard =
      "BEGIN:VCARD\nVERSION:4.0\nFN:" +
      contact.name +
      "\nTEL;TYPE=work,voice:" +
      contact.phone +
      "\nEMAIL:" +
      contact.email +
      "\nEND:VCARD";
    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);

    const newLink = document.createElement("a");
    newLink.download = contact.name + ".vcf";
    newLink.textContent = contact.name;
    newLink.href = url;

    newLink.click();

    closedTab();
  };

  const closedTab = (url) => {
    window.open(`${url ?? "about:blank"}`, "_self");
    window.history?.deleteAll();
  };
  return <Loading />;
};
export default SmartQr;
