import { Stack } from "@mui/material";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";

const Map = ({ width, borderRadius, height }) => {
  const data = useSelector((state) => state?.biz?.data);
  const lat = data?.bizLatLonl ? data?.bizLatLonl?.split(",") : [];
  const position = lat.length > 0 ? [+lat[0], +lat[1]] : null;

  return (
    <NullCheck valueForCheck={position}>
      <Stack
        width={width ? width : "100%"}
        borderRadius={borderRadius ?? "0"}
        borderColor="white"
      >
        <MapContainer
          style={{
            borderRadius: borderRadius ?? "0",
            height: height ? height : 250,
          }}
          center={position}
          zoom={18}
          minZoom={14}
          maxZoom={18}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>{data?.bizName}</Popup>
          </Marker>
        </MapContainer>
      </Stack>
    </NullCheck>
  );
};
export default Map;
