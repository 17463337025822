import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../layout";

/* pages router */
import Page404 from "../utils/Page404";
import TemplatesRoutes from "./TemplatesRoutes";
import ApiProvider from "../utils/ApiProvider";
import SmartQr from "../containers/SmartQr";
import ServicesSummary from "../containers/ServicesSummary";
import NavigateToMyBee from "../containers/NavigateToMyBee";

const Routers = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="*" errorElement={<Page404 />} element={<Page404 />} />
          <Route path="/" element={<NavigateToMyBee />} />
          <Route
            path="Services-Summary/:bizPath/:guid"
            element={<ServicesSummary />}
          />
          <Route path="smartQr/:guid" element={<SmartQr />} />
          <Route
            path="smartQr/:guid/:clientGuid/:clientItem"
            element={<SmartQr />}
          />
          <Route
            path="clientServiceCall/:bizPath/:empPath/:clientGuid/:clientItem"
            element={
              <ApiProvider>
                <TemplatesRoutes />
              </ApiProvider>
            }
          />
          <Route
            path="clientServiceCall/:bizPath/:empPath/:clientGuid"
            element={
              <ApiProvider>
                <TemplatesRoutes />
              </ApiProvider>
            }
          />
          <Route
            path="/:bizPath/:empPath"
            element={
              <ApiProvider>
                <TemplatesRoutes />
              </ApiProvider>
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default Routers;
