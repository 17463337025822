import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import Header from "./Header";
import Description from "./Description";
import Links from "../components/Links";
import Map from "../components/Map";
import CustomButton from "../../components/CustomButton";
import SignpostIcon from "@mui/icons-material/Signpost";
import Footer from "../../components/Footer";
import ToolsBar from "../components/ToolsBar";
import CsvButton from "../components/CsvButton";
import ContactUs from "../components/ContactUs";
import ServicesCall from "../components/servicesCall";
import { useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";
import Recommend from "../components/Recommend";
import VideoCarousel from "../components/VideoCarousel";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Design1Temp = () => {
  const { t } = useTranslation();
  const data = useSelector((state) => state?.biz?.data);
  const lat = data?.bizLatLonl ? data?.bizLatLonl?.split(",") : [];
  const position = lat.length > 0 ? [+lat[0], +lat[1]] : null;

  return (
    <Stack height="100%">
      <Stack
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
        height="100%"
      >
        <Header />

        <Stack
          direction="row"
          justifyContent="center"
          padding={2}
          gap={5}
          bgcolor="primary.main"
        >
          <Links iconSize={30} showTitle />
        </Stack>

        <ToolsBar notSticky />

        <Description />

        <NullCheck valueForCheck={data?.bizLatLonl}>
          <Accordion sx={{ padding: 2, bgcolor: "primary.main" }}>
            <AccordionSummary
              sx={{ bgcolor: "secondary.main" }}
              expandIcon={<ExpandMore color="primary" />}
            >
              <Typography fontWeight="bold" color="primary">
                {`${t("ADDR")} ${data?.bizAddr ? `- ${data?.bizAddr}` : ""}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: "secondary.main" }}>
              <Stack spacing={1} padding={1} bgcolor="primary.main">
                <Map position={position} height={200} />

                <Stack
                  padding={1}
                  justifyContent="center"
                  gap={2}
                  direction="row"
                >
                  <CustomButton
                    fullWidth
                    fontBold
                    color="secondary"
                    textColor="primary"
                    title="WAZE"
                    icon={<SignpostIcon color="primary" />}
                    onClick={() =>
                      window.open(
                        `https://waze.com/ul?ll=${data?.bizLatLonl}&navigate=yes`
                      )
                    }
                  />
                  <CustomButton
                    fullWidth
                    fontBold
                    textColor="primary"
                    color="secondary"
                    title="GOOGLE_MAP"
                    icon={<SignpostIcon color="primary" />}
                    onClick={() =>
                      window.open(
                        `https://maps.google.com/?ll=${data?.bizLatLonl}`
                      )
                    }
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </NullCheck>

        <ServicesCall />
        <VideoCarousel />
        <ContactUs />
        {/* <CsvButton /> */}

        <Recommend />

        <Footer />
      </Stack>
    </Stack>
  );
};
export default Design1Temp;
