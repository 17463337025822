import { Stack, useTheme } from "@mui/material";
import error404 from "../assets/error404.jpg";
import { useEffect } from "react";
import { setShowLoading } from "../store/sysSlice";
import { useDispatch } from "react-redux";

const Page404 = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(setShowLoading(false));
  }, []);

  return (
    <Stack
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <img
        alt=""
        src={error404}
        style={{
          width: "80%",
          border: `2px solid ${theme.palette.primary.main}`,
        }}
      />
    </Stack>
  );
};
export default Page404;
