import { Stack } from "@mui/material";
import Header from "./Header";
import Description from "./Description";
import Links from "../components/Links";
import Map from "../components/Map";
import CustomButton from "../../components/CustomButton";
import SignpostIcon from "@mui/icons-material/Signpost";
import Footer from "../../components/Footer";
import ToolsBar from "../components/ToolsBar";
import CsvButton from "../components/CsvButton";
import ContactUs from "../components/ContactUs";
import ServicesCall from "../components/servicesCall";
import { useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";
import Recommend from "../components/Recommend";
import VideoCarousel from "../components/VideoCarousel";

const ClassicTemp = () => {
  const data = useSelector((state) => state?.biz?.data);
  const lat = data?.bizLatLonl ? data?.bizLatLonl?.split(",") : [];
  const position = lat.length > 0 ? [+lat[0], +lat[1]] : null;

  return (
    <Stack height="100%">
      <Stack
        spacing={0.5}
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
        height="100%"
      >
        <Header />

        <Stack
          direction="row"
          justifyContent="center"
          padding={2}
          marginTop={0.5}
          gap={5}
          bgcolor="secondary.main"
          // bgcolor="primary.main"
        >
          <Links
            iconColor="secondary"
            bgcolor="primary.main"
            borderRadius={2}
            iconSize={35}
          />
        </Stack>

        <Description />

        <CsvButton />

        <VideoCarousel />

        <NullCheck valueForCheck={data?.bizLatLonl}>
          <Stack spacing={1} padding={1} bgcolor="primary.main">
            <Map position={position} borderRadius="0% 10%" />

            <Stack padding={1} justifyContent="center" gap={2} direction="row">
              <CustomButton
                fullWidth
                fontBold
                color="secondary"
                title="WAZE"
                icon={<SignpostIcon color="primary" />}
                onClick={() =>
                  window.open(
                    `https://waze.com/ul?ll=${data?.bizLatLonl}&navigate=yes`
                  )
                }
              />
              <CustomButton
                fullWidth
                fontBold
                color="secondary"
                title="GOOGLE_MAP"
                icon={<SignpostIcon color="primary" />}
                onClick={() =>
                  window.open(`https://maps.google.com/?ll=${data?.bizLatLonl}`)
                }
              />
            </Stack>
          </Stack>
        </NullCheck>

        <ServicesCall />

        <ContactUs />

        <Recommend />

        <Footer />
      </Stack>
      <ToolsBar />
    </Stack>
  );
};
export default ClassicTemp;
